import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { FullscreenModal } from '../../../../components/modal/fullscreenModal';
import Pagination from '../../../../components/pagination';
import { NegativeCardBalanceResponse } from '../../../../interface/cards';
import { usePendingCards } from '../../../../services/transaction';
import { capitalized } from '../../../../utils/capitalize';
import { isCurrency } from '../../../../utils/isCurrency';
import { IPendingTransaction, ITxnFilter } from '../../../../interface/txn';
import Input from '../../../../components/input';

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const PendingCards: FC<IProps> = ({ open, setOpen }) => {
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<ITxnFilter>();

  const { data, isLoading } = usePendingCards({
    pageNumber: currentPage,
    pageSize: size,
    ...filter,
  });

  return (
    <FullscreenModal
      isVisible={open}
      close={() => {
        setOpen(false);
      }}
      title="Pending Cards"
      header
      loading={isLoading}
    >
      <h2 className="font-medium text-grayText pb-4">
        Number of Cards ({data?.data.totalCount})
      </h2>
      <div className="grid md:grid-cols-2 mb-6 lg:grid-cols-4 gap-4">
        <Input
          type="date"
          className="text-xs"
          leading={<span className="opacity-50">Start</span>}
          onChange={(e) =>
            setFilter({ ...filter, startDate: e.target.value.trim() })
          }
        />
        <Input
          type="date"
          className="text-xs"
          leading={<span className="opacity-50">End</span>}
          onChange={(e) =>
            setFilter({ ...filter, endDate: e.target.value.trim() })
          }
        />
      </div>
      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto tableScrollBar">
          <div className=" inline-block min-w-full">
            <div className="overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-activeLinkBlack ">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Card Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Currency
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Transaction Amount
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Balance
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Brand
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Transaction Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data?.data?.transactions?.map(
                      (item: IPendingTransaction, index: number) => (
                        <tr key={item.id} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>
                          <td className="text-sm p-4">{item?.merchantName}</td>
                          <td className="text-sm p-4">{item?.currencyCode}</td>
                          <td className="text-sm p-4">
                            ${isCurrency(item?.transactionAmount)}
                          </td>
                          <td className="text-sm p-4">
                            ${isCurrency(item?.balanceAfterTransaction)}
                          </td>
                          <td className="text-sm p-4">{item?.network}</td>
                          <td className="text-sm p-4">
                            {capitalized(item?.transactionStatus)}
                          </td>
                          <td className="text-sm p-4">
                            {item.transactionTime &&
                              format(
                                new Date(item.transactionTime),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data?.data.totalCount! > 0 && (
          <Pagination
            totalItems={data?.data.totalCount!}
            itemsPerPage={size}
            setPage={setCurrentPage}
            page={currentPage}
            setSize={setSize}
          />
        )}
      </div>
    </FullscreenModal>
  );
};

export default PendingCards;
