import { IList } from './response';
import { IUser } from './user';

export interface ITxnFilter {
  search?: string;
  status?: string;
  purpose?: string;
  endDate?: string;
  startDate?: string;
  approved?: boolean;
  suspend?: boolean;
}

export interface ITxn {
  _id: string;
  user: IUser;
  reference: string;
  amount: number;
  disbursedAmount: number;
  gatewayCharge: number;
  smsCharge: number;
  settlement: number;
  cashback: number;
  activity: string;
  type: string;
  business?: string;
  paymentGateway: string;
  description: string;
  provider: string;
  purpose: string;
  status: string;
  requestPayload: any;
  responsePayload: any;
  view: any;
  meta: any;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface IDeclinePayoneerPayload {
  payload: { status: string; secret: string };
  id: string;
}

export interface IApprovePayoneerPayload {
  payload: { status: string; secret: string };
  id: string;
}

interface IMetadata {
  thirdPartyReference: string;
}

export interface IPostingHistory {
  entryCode: string;
  referenceNumber: string;
  reversalReferenceNumber: string;
  accountNumber: string;
  linkedAccountNumber: null;
  realDate: string;
  amount: number;
  openingBalance: number;
  balanceAfter: number;
  narration: string;
  instrumentNumber: string;
  postingRecordType: number;
  postedBy: string;
  financialDate: string;
  financialDateToBackdate: null;
  iPAddress: null;
  merchant: string;
  recipientName: null;
  renderName: null;
  recipientBank: null;
  senderBank: null;
  userID: null;
  hasCOTWaiver: boolean;
  forceDebit: boolean;
  postingType: number;
  transactionMethod: number;
  sessionId: string;
  charge: number;
  beneficiaryName: string;
  allowChangeCategory: boolean;
  categoryId: number;
  categorySet: boolean;
  tagId: number;
  beneficiaryReference: string;
  goalTitle: null;
  phoneNumberRecharged: null;
  billId: null;
  tier0Waiver: boolean;
  detailOfClosure: null;
  reasonForClosure: null;
  closedBy: null;
  metaData: any;
}

export interface IData {
  postingsHistory: IPostingHistory[];
  message: null;
  statusCode: string;
  totalRecordInStore: number;
  totalDebit: number;
  totalCredit: number;
}

export interface IKudaTxn {
  status: boolean;
  message: string;
  data: IData;
}

interface IAccount {
  accountBalance: number;
  accountName: string;
  accountNumber: string;
  accountProduct: string;
  accountType: string;
  _id: string;
}

export interface ISafeHavenTxn {
  account: IAccount;
  amount: number;
  cbaTransactionId: string;
  client: string;
  narration: string;
  paymentReference: string;
  provider: string;
  providerChannel: string;
  runningBalance: number;
  transactionDate: string;
  type: string;
  valueDate: string;
  _id: string;
}

export enum TypeEnum {
  Credit = 'Credit',
  Debit = 'Debit',
}

export interface IPendingTransactionResponse {
  isSuccessful: boolean;
  responseMessage: string;
  responseCode: string;
  transactions: IPendingTransaction[];
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface IPendingTransaction {
  id: number;
  cardId: string;
  cardGuid: string;
  uniqueKey: string;
  merchantName: string;
  merchantCity: string;
  merchantState: string;
  merchantMcc: string;
  merchantMid: string;
  merchantCountry?: string;
  transactionType: string;
  transactionStatus: string;
  transactionAmount: number;
  currencyCode: string;
  transactionTime: string;
  description?: string;
  network: string;
  authorizationAmount: number;
  authorizationCurrencyCode?: string;
  balanceAfterTransaction: number;
  createdAt: string;
  updatedAt: string;
}
