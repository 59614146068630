import React, { useState } from 'react';
import { INewBusinessResponse } from '../../../../interface/business';
import { Link } from 'react-router-dom';
import { Modal } from '../../../../components/modal';
import { capitalized } from '../../../../utils/capitalize';
import { StatusEnum } from '../../../../utils/constants';
import Switch from '../../../../components/switch';
import { CopyBlock, dracula } from 'react-code-blocks';
import { isCurrency } from '../../../../utils/isCurrency';

const RegDetails = ({ item }: { item: INewBusinessResponse }) => {
  const [open, setOpen] = useState(false);
  const [proprietorVisibility, setProprietorVisibility] = useState(
    Array(item.proprietor.length).fill(false)
  );

  const toggleProprietorVisibility = (index: number) => {
    const newVisibility = [...proprietorVisibility];
    newVisibility[index] = !newVisibility[index];
    setProprietorVisibility(newVisibility);
  };

  return (
    <div>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          Details
        </Link>
      </div>

      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        title="Business Registration details"
        header={true}
      >
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Status</small>
            <small>
              {item.process === StatusEnum.FAILED && (
                <span className="text-red-500">
                  {capitalized(StatusEnum.FAILED)}
                </span>
              )}
              {item.process === StatusEnum.SUCCESS && (
                <span className="text-green-500">
                  {capitalized(StatusEnum.SUCCESS)}
                </span>
              )}
              {item.process === StatusEnum.PENDING && (
                <span className=" text-amber-500">
                  {capitalized(StatusEnum.PENDING)}
                </span>
              )}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Customer</small>
            <Link
              to={`/app/user/${item.user?._id}`}
              onClick={() => setOpen(false)}
            >
              <small className="text-blue">{`${item.user?.firstName} ${item.user?.lastName}`}</small>
            </Link>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Reference</small>
            <small>₦{item.reference}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Payment</small>
            <small>₦{isCurrency(Number(item.payment))}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Proposed name 1</small>
            <small>{item.business.name1}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Proposed name 2</small>
            <small>{item.business.name2}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Proposed name 3</small>
            <small>{item.business.name3}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Category</small>
            <small>{item.business.category}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Address</small>
            <small>{item.business.address}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>State</small>
            <small>{item.business.state}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>City</small>
            <small>{item.business.city}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Description</small>
            <small>{item.business.description}</small>
          </div>
        </div>
        <div>
          <div className="py-4 px-4">
            {item.proprietor && item.proprietor.length > 0 && (
              <>
                {item.proprietor.map((proprietor, index) => (
                  <div key={index} className="mb-4">
                    <div className="flex justify-between items-center mb-2">
                      <small>{`Proprietor ${index + 1}`}</small>
                      <Switch
                        checked={proprietorVisibility[index]}
                        onCheck={() => toggleProprietorVisibility(index)}
                      />
                    </div>

                    {proprietorVisibility[index] && (
                      <pre>
                        <CopyBlock
                          text={JSON.stringify(proprietor, null, 2)}
                          showLineNumbers
                          theme={dracula}
                          wrapLines={true}
                          codeBlock
                        />
                      </pre>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RegDetails;
