import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../../../../../context/globalContext';
import ItemCard from '../../../../../../components/itemCard';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import { Modal } from '../../../../../../components/modal';
import {
  useGetSettings,
  useUpdateSettingsData,
} from '../../../../../../services/settings';
import { useToast } from '../../../../../../context/toast';

const Build = () => {
  const [open, setOpen] = useState(false);
  const { data, refetch } = useGetSettings();
  const figurData = data?.data.figur;
  const { mutate, isLoading } = useUpdateSettingsData();
  const [build, setBuild] = useState<string>('');
  const { deviceDetails, setDeviceDetails, cardType } =
    useContext(GlobalContext);
  const { addToast } = useToast();

  const submit = () => {
    mutate(
      {
        figur: {
          ...figurData,
          android:
            cardType === 'android'
              ? { ...figurData?.android, build: Number(build) }
              : figurData?.android,
          ios:
            cardType === 'ios'
              ? { ...figurData?.ios, build: Number(build) }
              : figurData?.ios,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
          setDeviceDetails({ ...deviceDetails, build: Number(build) });
        },
      }
    );
  };

  useEffect(() => {
    if (deviceDetails) {
      setBuild(String(deviceDetails.build));
    }
  }, [deviceDetails, setBuild]);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Build" hint={String(deviceDetails.build)} />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Build"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <div className="flex justify-between">
              <h5>Enter Build</h5>
            </div>
            <Input
              parentClassName="mt-2"
              defaultValue={build}
              onChange={(e) => setBuild(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Build;
