import React, { useState } from 'react';
import { IAirtimeToCash } from '../../../../../interface/settings';
import ItemCard from '../../../../../components/itemCard';
import { Modal } from '../../../../../components/modal';
import { IProps } from './limit-per-day';
import { useUpdateSettingsData } from '../../../../../services/settings';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import { useToast } from '../../../../../context/toast';

const Min = ({ item, open, setOpen, refetch }: IProps) => {
  const [min, setMin] = useState<string>('');
  const { mutate, isLoading } = useUpdateSettingsData();

  const { addToast } = useToast();

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: 'Successfully Updated', type: 'success' });
    setOpen(false);
  };

  const submit = () => {
    mutate(
      {
        airtimeToCash: {
          ...item,
          min: Number(min),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };
  return (
    <>
      <div className="rounded-md bg-dark mb-6" onClick={() => setOpen(true)}>
        <ItemCard title="Min" hint={item?.min} />
      </div>

      <Modal
        isVisible={open}
        close={setOpen}
        center
        title="Min"
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <Input
          type="text"
          onChange={(e) => setMin(e.target.value)}
          defaultValue={item?.min}
        />
      </Modal>
    </>
  );
};

export default Min;
