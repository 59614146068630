import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useToast } from '../context/toast';
import {
  IList,
  IPendingTransationList,
  IResponse,
  ITransaction,
} from '../interface/response';
import {
  IKudaTxn,
  IPendingTransaction,
  IPendingTransactionResponse,
  ISafeHavenTxn,
  ITxn,
} from '../interface/txn';
import Axios from './axios';

const txn = async (params: object) => {
  const response: AxiosResponse<IResponse<IList<ITxn>>> = await Axios.get(
    '/transaction',
    {
      params,
    }
  );
  return response.data;
};

const txnStatus = async (param: string) => {
  const response: AxiosResponse<IResponse<ITransaction>> = await Axios.get(
    `/safehaven/transfer/status/${param}`
  );
  return response.data;
};

const kudaTxnHistory = async (params: object) => {
  const response: AxiosResponse<IResponse<IKudaTxn>> = await Axios.get(
    '/transaction/kuda/history',
    {
      params,
    }
  );
  return response.data;
};

const SafeHavenTxnHistory = async (params: object) => {
  const response: AxiosResponse<IResponse<ISafeHavenTxn[]>> = await Axios.get(
    '/safehaven/statement',
    {
      params,
    }
  );
  return response.data;
};

const kudaBalance = async () => {
  const response: AxiosResponse<IResponse> = await Axios.get(
    '/transaction/kuda/balance'
  );
  return response.data;
};

const reverseBank = async ({ id }: { id: string }) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.get(
    `/transaction/bank/reversal/${id}`
  );
  return response.data;
};

const reverseBills = async ({ id }: { id: string }) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.get(
    `/transaction/bills/reversal/${id}`
  );
  return response.data;
};

const requery = async ({ reference }: { reference: string }) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.get(
    `/transaction/bank/transfer/requery/${reference}`
  );
  return response.data;
};

const pendingCards = async (params?: object) => {
  const response: AxiosResponse<IResponse<IPendingTransactionResponse>> =
    await Axios.get(`/card/admin/cross-border/pending`, { params });
  return response.data;
};

const businessTransactions = async ({
  params,
  id,
}: {
  params: object;
  id: string | undefined;
}) => {
  const response: AxiosResponse<IResponse<IList<ITxn>>> = await Axios.get(
    `/transaction?business=${id}`,
    { params }
  );

  return response.data;
};

export const useTransactions = (params: object) => {
  const { addToast } = useToast();
  return useQuery(['transaction', params], () => txn(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useTransactionsStatus = (
  param: string,
  shouldRequery: boolean
) => {
  const { addToast } = useToast();
  return useQuery(['transaction', param], () => txnStatus(param), {
    enabled: shouldRequery,
    onSuccess: (res) => {
      addToast({
        msg: res.message || 'Successful Requery',
        type: 'success',
      });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useKudaTxnHistory = (params: object) => {
  const { addToast } = useToast();
  return useQuery(['kuda transaction', params], () => kudaTxnHistory(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg:
            err.response.data.message || 'Error fetching transaction history',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useSafeHavenTxnHistory = (params: object) => {
  const { addToast } = useToast();
  return useQuery(
    ['safe haven transaction', params],
    () => SafeHavenTxnHistory(params),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg:
              err.response.data.message || 'Error fetching transaction history',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
      keepPreviousData: true,
    }
  );
};

export const useKudaBalance = () => {
  const { addToast } = useToast();
  return useQuery(['kuda balance'], kudaBalance, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching balance',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useReverseBanks = ({
  id,
  enable,
  setEnable,
}: {
  id: string;
  enable: boolean;
  setEnable: (value: boolean) => void;
}) => {
  const { addToast } = useToast();

  return useQuery(['reverse bank', id], () => reverseBank({ id }), {
    enabled: enable,
    onSuccess: (res: AxiosResponse<IResponse>) => {
      addToast({
        msg: 'Transaction Reversed',
        type: 'success',
      });
      setEnable(false);
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      setEnable(false);
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useReverseBills = ({
  id,
  enable,
  setEnable,
}: {
  id: string;
  enable: boolean;
  setEnable: (value: boolean) => void;
}) => {
  const { addToast } = useToast();

  return useQuery(['reverse bills', id], () => reverseBills({ id }), {
    enabled: enable,
    onSuccess: (res: AxiosResponse<IResponse>) => {
      addToast({
        msg: 'Transaction Reversed',
        type: 'success',
      });
      setEnable(false);
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      setEnable(false);
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useRequery = ({
  reference,
  enable,
  seterrorMessage,
  setsuccessMessage,
  setEnable,
}: {
  reference: string;
  enable: boolean;
  setsuccessMessage: (value: string) => void;
  seterrorMessage: (value: string) => void;
  setEnable: (value: boolean) => void;
}) => {
  const { addToast } = useToast();

  return useQuery(['requery', reference], () => requery({ reference }), {
    enabled: enable,
    onSuccess: (res: AxiosResponse<IResponse>) => {
      setsuccessMessage(res.data.message ?? 'Transaction requery successful');
      setEnable(false);
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      setEnable(false);
      if (err.response) {
        seterrorMessage(
          err.response.data.message ?? 'Error while performing requery'
        );
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useBusinessTransactions = ({
  params,
  id,
}: {
  params: object;
  id: string | undefined;
}) => {
  const { addToast } = useToast();
  return useQuery(
    ['transaction', params],
    () => businessTransactions({ params, id }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || 'Error fetching user',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
      keepPreviousData: true,
    }
  );
};

export const usePendingCards = (params: object) => {
  const { addToast } = useToast();
  return useQuery(['pending-cards', params], () => pendingCards(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching pending cards',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};
