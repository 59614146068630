import React, { useState } from 'react';
import { ISettingsResponse } from '../../../../interface/settings';
import Switch from '../../../../components/switch';
import { useToast } from '../../../../context/toast';
import { useUpdateSettingsData } from '../../../../services/settings';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import Input from '../../../../components/input';

interface IReferralInterface {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const Referral = ({ settingsData, refetch }: IReferralInterface) => {
  const referral = settingsData?.commission;
  const [activeDollar, setActiveDollar] = useState<boolean>(
    referral?.activeDollar ?? false
  );
  const [activeNaira, setActiveNaira] = useState<boolean>(
    referral?.activeNaira ?? false
  );
  const [open, setOpen] = useState(false);
  const [dollarEarning, setDollarEarning] = useState<string>('');
  const [nairaEarning, setNairaEarning] = useState<string>('');
  const [minDolTxn, setMinDolTxn] = useState<string>('');
  const [minNaira, setMinNaira] = useState<string>('');

  const { addToast } = useToast();
  const { mutate, isLoading } = useUpdateSettingsData();

  const handleActiveDollarChange = () => {
    setActiveDollar(!activeDollar);
    mutate(
      {
        commission: {
          ...referral,
          activeDollar: !referral?.activeDollar,
        },
      },
      {
        onSuccess: () => {
          refetch();

          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const handleActiveNairaChange = () => {
    setActiveNaira(!activeNaira);
    mutate(
      {
        commission: {
          ...referral,
          activeNaira: !referral?.activeNaira,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const submit = () => {
    mutate(
      {
        commission: {
          ...referral,
          dollarEarning: Number(dollarEarning) || referral?.dollarEarning,
          minDollarTransaction:
            Number(minDolTxn) || referral?.minDollarTransaction,
          nairaEarning: Number(nairaEarning) || referral?.nairaEarning,
          minNairaSpend: Number(minNaira) || referral?.minNairaSpend,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="py-4 mb-6 bg-transparent border border-grey px-2 rounded-md flex justify-between items-center">
        <h5>Activate Dollar</h5>
        <Switch checked={activeDollar} onCheck={handleActiveDollarChange} />
      </div>

      <div className="py-4 mb-6 bg-transparent border border-grey px-2 rounded-md flex justify-between items-center">
        <h5>Activate Naira</h5>
        <Switch checked={activeNaira} onCheck={handleActiveNairaChange} />
      </div>

      <div>
        <div className="bg-dark rounded-t-md">
          <div className="flex justify-between px-4 py-4">
            <h5>Dollar Earning</h5>
            <h4>{referral?.dollarEarning}</h4>
          </div>
          <hr className="border-grey" />
          <div className="flex justify-between px-4 py-4">
            <h5>Minimum Dollar Transaction</h5>
            <h4>{referral?.minDollarTransaction}</h4>
          </div>
          <hr className="border-grey" />
          <div className="flex justify-between px-4 py-4">
            <h5>Minimum Naira Spend</h5>
            <h4>{referral?.minNairaSpend}</h4>
          </div>
          <hr className="border-grey" />
          <div className="flex justify-between px-4 py-4">
            <h5>Naira Earning</h5>
            <h4>{referral?.nairaEarning}</h4>
          </div>
        </div>
        <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
          <Button onClick={() => setOpen(true)}>Edit Commission</Button>
        </div>
      </div>

      <Modal
        isVisible={open}
        close={setOpen}
        title={'Commission'}
        padding={false}
        center
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <div className="text-sm">
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Dollar Earning</h5>
            <Input
              type="text"
              onChange={(e) => setDollarEarning(e.target.value)}
              defaultValue={referral?.dollarEarning}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Minimum Dollar Transaction</h5>
            <Input
              type="text"
              onChange={(e) => setMinDolTxn(e.target.value)}
              defaultValue={referral?.minDollarTransaction}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Minimum Naira Spend</h5>
            <Input
              type="text"
              onChange={(e) => setMinNaira(e.target.value)}
              defaultValue={referral?.minNairaSpend}
            />
          </div>

          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Naira Earning</h5>
            <Input
              type="text"
              onChange={(e) => setNairaEarning(e.target.value)}
              defaultValue={referral?.nairaEarning}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Referral;
