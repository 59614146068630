import { useEffect, useState } from 'react';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import Input from '../../../../components/input';
import { useToast } from '../../../../context/toast';
import { ISettingsResponse } from '../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../services/settings';
import { capitalized } from '../../../../utils/capitalize';

interface IReassignProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const ReassignAccount = ({ settingsData, refetch }: IReassignProps) => {
  const figurData = settingsData?.figur;
  const { mutate, isLoading } = useUpdateSettingsData();
  const [duration, setDuration] = useState<string>('');
  const [timeLine, settimeLine] = useState<string>('');
  const { addToast } = useToast();

  const submit = () => {
    mutate(
      {
        figur: {
          ...figurData,
          inactiveAccountWindow: {
            timeline: timeLine,
            duration: Number(duration),
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const getTimeLine = () => {
    return timeLineData
      ? timeLineData.map((item) => {
          return {
            label: capitalized(item.time),
            value: item.time,
          };
        })
      : [];
  };

  useEffect(() => {
    if (figurData?.inactiveAccountWindow) {
      setDuration(String(figurData?.inactiveAccountWindow.duration));
      settimeLine(figurData?.inactiveAccountWindow.timeline);
    }
  }, [figurData]);

  return (
    <div className="md:w-[30rem] w-full text-sm">
      <div className="bg-dark rounded-t-md px-4 py-4">
        <h5 className="text-sm mb-6">ACCOUNT NUMBER REASSIGNMENT TIMER</h5>
        <div className="grid grid-cols-2 gap-4">
          <Input
            onChange={(e) => setDuration(e.target.value)}
            placeholder="Duration"
            defaultValue={duration}
          />

          <Dropdown
            label={'Timeline'}
            option={getTimeLine()}
            onChange={(e) => settimeLine(e?.value)}
            defaultValue={timeLine}
          />
        </div>
      </div>

      <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
        <Button onClick={submit} loading={isLoading} disabled={isLoading}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ReassignAccount;

const timeLineData = [{ time: 'minutes' }, { time: 'days' }];
