import React, { useContext, useEffect, useState } from 'react';
import {
  ICard,
  ICustomDenomination,
  IPreDenomination,
} from '../../../../../../interface/settings';
import GlobalContext from '../../../../../../context/globalContext';
import { useToast } from '../../../../../../context/toast';
import Button from '../../../../../../components/button';
import Dropdown from '../../../../../../components/dropdown';
import { Close } from '../../../../../../components/icons';
import Input from '../../../../../../components/input';
import { Modal } from '../../../../../../components/modal';

interface ICardType {
  index: number;
  countryIndex: number;
  item: ICard;
}

const EditCardType = ({ index, item, countryIndex }: ICardType) => {
  const { giftCardInputs, setGiftCardInputs } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [predenominations, setpredenominations] = useState<IPreDenomination[]>(
    []
  );
  const [customdenominations, setcustomdenominations] = useState<
    ICustomDenomination[]
  >([]);
  const [cardType, setCardType] = useState<string>('');
  const { addToast } = useToast();

  const save = () => {
    if (!cardType) {
      addToast({ msg: 'Please add a card type', type: 'error' });
    } else {
      setGiftCardInputs({
        ...giftCardInputs,
        availableCountry: giftCardInputs.availableCountry.map(
          (item, itemIndex) =>
            itemIndex === countryIndex
              ? {
                  ...item,
                  card: item.card?.map((cardItem, cardIndex) =>
                    cardIndex === index
                      ? {
                          type: cardType,
                          customDenomination: customdenominations,
                          predefinedDenomination: predenominations,
                        }
                      : cardItem
                  ),
                }
              : item
        ),
      });
      setOpen(false);
    }
  };

  const handleAddPreDenominations = () => {
    const newDenomination = {
      value: 0,
      cRate: 0,
      vRate: 0,
    };
    setpredenominations([...predenominations, newDenomination]);
  };

  const handleAddCustomDenominations = () => {
    const newDenomination = {
      range: { min: 0, max: 0 },
      cRate: 0,
      vRate: 0,
    };
    setcustomdenominations([...customdenominations, newDenomination]);
  };

  const handleInputChange = (
    value: string,
    index: number,
    inputName: string
  ) => {
    setpredenominations((prevState) => {
      const updatedPredenominations: IPreDenomination[] = [...prevState];
      if (inputName === 'vRate') {
        updatedPredenominations[index].vRate = Number(value);
      } else if (inputName === 'value') {
        updatedPredenominations[index].value = Number(value);
      } else if (inputName === 'cRate') {
        updatedPredenominations[index].cRate = Number(value);
      }
      return updatedPredenominations;
    });
  };

  const handleCustomInputsChange = (
    value: string,
    index: number,
    inputName: string
  ) => {
    setcustomdenominations((prevState) => {
      const updatedPredenominations: ICustomDenomination[] = [...prevState];
      if (inputName === 'vRate') {
        updatedPredenominations[index].vRate = Number(value);
      } else if (inputName === 'min') {
        updatedPredenominations[index].range.min = Number(value);
      } else if (inputName === 'max') {
        updatedPredenominations[index].range.max = Number(value);
      } else if (inputName === 'cRate') {
        updatedPredenominations[index].cRate = Number(value);
      }
      return updatedPredenominations;
    });
  };

  const getCardName = () => {
    return CardTypeName
      ? CardTypeName.map((cardName) => {
          return {
            label: cardName.name,
            value: cardName.name,
          };
        })
      : [];
  };

  useEffect(() => {
    if (item) {
      setCardType(item.type);
      setcustomdenominations(item.customDenomination);
      setpredenominations(item.predefinedDenomination);
    }
  }, [item]);

  return (
    <div>
      <h5
        className="text-primary cursor-pointer"
        onClick={() => {
          item && setOpen(true);
        }}
      >
        Edit
      </h5>

      <div>
        <Modal
          padding={false}
          isVisible={open}
          center
          close={() => {
            setOpen(false);
          }}
          title="Card type & Value"
          header={true}
          width="medium"
          showFooter
          footer={
            <div className="flex justify-end">
              <Button onClick={save}>Save Changes</Button>
            </div>
          }
        >
          <div className="flex flex-col gap-4">
            <div className="border-b border-gray">
              <div className="flex flex-col gap-2 py-4 px-4">
                <h5>Card type name</h5>
                <Dropdown
                  label={''}
                  option={getCardName()}
                  onChange={(e) => setCardType(e?.value)}
                  parentClassName="bg-transparent border-gray border-[1px]"
                  defaultValue={cardType}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 px-4">
              <h5 className="mb-3">Predefined Denominations</h5>
              {predenominations.length > 0 && (
                <div>
                  <div className="grid grid-cols-[40%_25%_25%_10%] bg-ash py-2 mb-2">
                    <h5 className="ml-2">Value</h5>
                    <h5>C-Rate</h5>
                    <h5>V-Rate</h5>
                  </div>
                  {predenominations.map((denomination, index) => (
                    <div
                      className="grid grid-cols-[40%_25%_25%_10%] items-center gap-1 mb-1"
                      key={index}
                    >
                      <Input
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, 'value')
                        }
                        defaultValue={denomination.value}
                      />
                      <Input
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, 'cRate')
                        }
                        defaultValue={denomination.cRate}
                      />
                      <Input
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, 'vRate')
                        }
                        defaultValue={denomination.vRate}
                      />
                      <Close
                        className="ml-2"
                        onClick={() =>
                          setpredenominations(
                            predenominations.filter(
                              (item, itemIndex) => itemIndex !== index
                            )
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              <Button
                className="border border-blue !py-3"
                bgColor="bg-transparent "
                onClick={handleAddPreDenominations}
              >
                Add Predefined Denominations
              </Button>
            </div>

            <div className="flex flex-col gap-2 pb-4 px-4">
              <h5 className="mb-3">Custom Denominations</h5>
              {customdenominations.length > 0 && (
                <div>
                  <div className="grid grid-cols-[40%_25%_25%_10%] bg-ash py-2 mb-2">
                    <h5 className="ml-2">Value</h5>
                    <h5>C-Rate</h5>
                    <h5>V-Rate</h5>
                  </div>
                  {customdenominations.map((denomination, index) => (
                    <div
                      className="grid grid-cols-[40%_25%_25%_10%] items-center gap-1 mb-1"
                      key={index}
                    >
                      <div className="flex gap-1 items-center">
                        <Input
                          onChange={(e) =>
                            handleCustomInputsChange(
                              e.target.value,
                              index,
                              'min'
                            )
                          }
                          defaultValue={denomination.range.min}
                        />
                        <h5>To</h5>
                        <Input
                          onChange={(e) =>
                            handleCustomInputsChange(
                              e.target.value,
                              index,
                              'max'
                            )
                          }
                          defaultValue={denomination.range.max}
                        />
                      </div>
                      <Input
                        onChange={(e) =>
                          handleCustomInputsChange(
                            e.target.value,
                            index,
                            'cRate'
                          )
                        }
                        defaultValue={denomination.cRate}
                      />
                      <Input
                        onChange={(e) =>
                          handleCustomInputsChange(
                            e.target.value,
                            index,
                            'vRate'
                          )
                        }
                        defaultValue={denomination.vRate}
                      />
                      <Close
                        className="ml-2"
                        onClick={() =>
                          setcustomdenominations(
                            customdenominations.filter(
                              (item, itemIndex) => itemIndex !== index
                            )
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              <Button
                className="border-blue border !py-3"
                bgColor="bg-transparent "
                onClick={handleAddCustomDenominations}
              >
                Add Custom Denominations
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EditCardType;

const CardTypeName = [
  { name: 'E-code' },
  { name: 'physical' },
  { name: 'physical (Cash Receipt)' },
  { name: 'physical (Debit Receipt)' },
  { name: 'physical (No Receipt)' },
];
