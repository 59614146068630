import { useEffect, useState } from 'react';
import Button from '../../../../components/button';
import Dropdown, { IDropdownList } from '../../../../components/dropdown';
import { Close } from '../../../../components/icons';
import Input from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import Switch from '../../../../components/switch';
import { useToast } from '../../../../context/toast';
import { ICashBack, ISettingsResponse } from '../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../services/settings';
import { capitalized } from '../../../../utils/capitalize';

interface ICashBackProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const CashBack = ({ settingsData, refetch }: ICashBackProps) => {
  const cashBackData = settingsData?.cashback;
  const { mutate } = useUpdateSettingsData();
  const [open, setOpen] = useState(false);
  const [cashBack, setCashBack] = useState<ICashBack>(cashBackData!);
  const [frequency, setFrequency] = useState<boolean>(false);
  const [limit, setLimit] = useState<boolean>(false);
  const [minimumSpend, setMinimumSpend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [service, setService] = useState<string[]>(cashBack.service!);
  const [active, setActive] = useState<boolean>(cashBack.active);
  const { addToast } = useToast();

  const handleFrequencyChange = () => {
    setFrequency(!frequency);
  };

  const handleActiveChange = () => {
    setActive(!active);
    mutate(
      {
        cashback: {
          ...cashBack,
          active: !cashBack.active,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const handleLimitChange = () => {
    setLimit(!limit);
  };

  const handleMinimumSpendChange = () => {
    setMinimumSpend(!minimumSpend);
  };

  const submit = () => {
    setLoading(true);
    mutate(
      {
        cashback: {
          ...cashBack,
          frequency: frequency === true ? 'UNLIMITED' : cashBack.frequency,
          limit: limit === true ? 'UNLIMITED' : cashBack.limit,
          minimumSpend: minimumSpend === true ? 'ANY' : cashBack.minimumSpend,
          service: service,
          active: active,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setLoading(false);
          setOpen(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const handleDeleteItem = (item: string) => {
    setService(service.filter((service) => service !== item));
  };

  const handleAddService = (item: string) => {
    if (service.indexOf(item) !== -1) {
      setService(service);
    } else setService([...service, item]);
  };

  useEffect(() => {
    if (cashBack.frequency === 'UNLIMITED') {
      setFrequency(true);
    }
    if (cashBack.limit === 'UNLIMITED') {
      setLimit(true);
    }
    if (cashBack.minimumSpend === 'ANY') {
      setMinimumSpend(true);
    }
  }, [cashBack]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="py-4 mb-6 bg-transparent border border-grey px-2 rounded-md flex justify-between items-center">
        <h5>Activate Cash Back</h5>
        <Switch checked={active} onCheck={handleActiveChange} />
      </div>
      <div>
        <div className="bg-dark rounded-t-md">
          <div className="flex justify-between px-4 py-4">
            <h5>Frequency</h5>
            <h4>{cashBackData?.frequency}</h4>
          </div>
          <hr className="border-grey" />
          <div className="flex justify-between py-4 px-4">
            <h5>Minimum Spend</h5>
            <h4>{cashBackData?.minimumSpend}</h4>
          </div>
          <hr className="border-grey" />

          <div className="flex justify-between py-4 px-4">
            <h5>Cashback Amount</h5>
            <h4>{cashBackData?.cashbackAmount}</h4>
          </div>
          <hr className="border-grey" />

          <div className="flex justify-between py-4 px-4">
            <h5>Cashback Type</h5>
            <h4>{cashBackData?.minimumSpend}</h4>
          </div>
          <hr className="border-grey" />

          <div className="flex justify-between py-4 px-4">
            <h5>Limit</h5>
            <h4>{cashBackData?.limit}</h4>
          </div>
        </div>
        <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
          <Button onClick={() => setOpen(true)}>Edit Cash Back</Button>
        </div>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        title={'Cash Back'}
        padding={false}
        center
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        }
      >
        <div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <h5>Service</h5>
              <div className="text-xs">
                <Dropdown
                  option={serviceData}
                  label={'Select Option'}
                  onChange={(e) => handleAddService(e?.value)}
                />

                <div className="flex mt-2 gap-2 justify-end">
                  {service.map((item: string, index: number) => (
                    <div
                      className="p-2 flex items-center relative border border-gray rounded-[50px]"
                      key={index}
                    >
                      <div>{capitalized(item)}</div>
                      <div
                        className="absolute top-[-6px] right-[1px] border border-gray w-[15px] flex items-center justify-center h-[15px] rounded-[50px] cursor-pointer"
                        onClick={() => handleDeleteItem(item)}
                      >
                        <Close className="w-[9px] h-[9px]" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Start Date</h5>
              <div className="w-2/5">
                <Input
                  type="datetime-local"
                  onChange={(e) =>
                    setCashBack({ ...cashBack, startDate: e.target.value })
                  }
                  defaultValue={cashBack.startDate}
                  className="text-xs"
                />
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>End Date</h5>
              <div className="w-2/5">
                <Input
                  type="datetime-local"
                  onChange={(e) =>
                    setCashBack({ ...cashBack, endDate: e.target.value })
                  }
                  defaultValue={cashBack.endDate}
                  className="text-xs"
                />
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Frequency</h5>
              <div className="w-2/5">
                <Input
                  type="text"
                  onChange={(e) =>
                    setCashBack({
                      ...cashBack,
                      frequency: Number(e.target.value),
                    })
                  }
                  className={`text-xs ${
                    frequency === true ? 'opacity-50' : ''
                  }`}
                  defaultValue={
                    cashBack.frequency === 'UNLIMITED' ? '' : cashBack.frequency
                  }
                  disabled={frequency === true}
                  trailing={
                    <div className="flex items-center text-xs">
                      <h6 className="mr-2">Unlimited</h6>
                      <Switch
                        checked={frequency}
                        onCheck={handleFrequencyChange}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Limit</h5>
              <div className="w-2/5">
                <Input
                  type="text"
                  onChange={(e) =>
                    setCashBack({
                      ...cashBack,
                      limit: Number(e.target.value),
                    })
                  }
                  className={`text-xs ${limit === true ? 'opacity-50' : ''}`}
                  defaultValue={
                    cashBack.limit === 'UNLIMITED' ? '' : cashBack.limit
                  }
                  disabled={limit === true}
                  trailing={
                    <div className="flex items-center text-xs">
                      <h6 className="mr-2">Unlimited</h6>
                      <Switch checked={limit} onCheck={handleLimitChange} />
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Minimum Spend</h5>
              <div className="w-2/5">
                <Input
                  type="text"
                  onChange={(e) =>
                    setCashBack({
                      ...cashBack,
                      minimumSpend: Number(e.target.value),
                    })
                  }
                  defaultValue={
                    cashBack.minimumSpend === 'ANY' ? '' : cashBack.minimumSpend
                  }
                  disabled={minimumSpend === true}
                  trailing={
                    <div className="flex items-center text-xs">
                      <h6 className="mr-2">Any</h6>
                      <Switch
                        checked={minimumSpend}
                        onCheck={handleMinimumSpendChange}
                      />
                    </div>
                  }
                  className={`text-xs ${
                    minimumSpend === true ? 'opacity-50' : ''
                  }`}
                />
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Cashback amount</h5>
              <div className="w-2/5">
                <Input
                  type="text"
                  onChange={(e) =>
                    setCashBack({
                      ...cashBack,
                      cashbackAmount: Number(e.target.value),
                    })
                  }
                  defaultValue={cashBack.cashbackAmount}
                  className="text-xs"
                />
              </div>
            </div>
          </div>

          <div className="border-b border-gray">
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Cashback type</h5>
              <div className="text-xs w-2/5">
                <Dropdown
                  label={'Select Option'}
                  option={cashBackType}
                  onChange={(e) =>
                    setCashBack({ ...cashBack, cashbackType: e?.value })
                  }
                  defaultValue={capitalized(cashBack.cashbackType)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CashBack;

const cashBackType: IDropdownList[] = [
  { value: 'FIXED_AMOUNT', label: 'Fixed Amount' },
  { value: 'PERCENTAGE', label: 'Percentage' },
];

const serviceData: IDropdownList[] = [
  { value: 'cable', label: 'Cable' },
  { value: 'electricity', label: 'Electricity' },
  { value: 'mobileData', label: 'Mobile Data' },
  { value: 'airtime', label: 'Airtime' },
  { value: 'cheapData', label: 'Cheap Data' },
];
