import { useEffect, useState } from 'react';
import ItemCard from '../../../../../components/itemCard';
import { ISettingsResponse, ITiers } from '../../../../../interface/settings';
import { Modal } from '../../../../../components/modal';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import Switch from '../../../../../components/switch';
import { useUpdateSettingsData } from '../../../../../services/settings';
import { useToast } from '../../../../../context/toast';

interface ITransactionLimitProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const TransactionLimits = ({
  settingsData,
  refetch,
}: ITransactionLimitProps) => {
  const transactionlimit = settingsData?.transactionLimits;
  const [open, setOpen] = useState<boolean>(false);
  const [editItem, setEdititem] = useState<ITiers>({} as ITiers);
  const [balance, setBalance] = useState<boolean>(false);
  const [limit, setLimit] = useState<boolean>(false);
  const { mutate, isLoading } = useUpdateSettingsData();
  const { addToast } = useToast();

  const handleBalanceChange = () => {
    setBalance(!balance);
  };

  const handleLimitChange = () => {
    setLimit(!limit);
  };

  const submit = () => {
    mutate(
      {
        transactionLimits: {
          tiers: transactionlimit?.tiers.map((item) =>
            item.name === editItem.name
              ? {
                  ...editItem,
                  transferPerTime: editItem.transferPerTime,
                  balance: balance === true ? 'Unlimited' : editItem.balance,
                  limitPerDay:
                    limit === true ? 'Unlimited' : editItem.limitPerDay,
                }
              : item
          ),
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  useEffect(() => {
    if (editItem.limitPerDay === 'Unlimited') {
      setLimit(true);
    }
    if (editItem.balance === 'Unlimited') {
      setBalance(true);
    }
  }, [editItem]);

  return (
    <>
      <div className="rounded-md bg-dark md:w-96 w-full text-sm">
        {transactionlimit?.tiers.map((item, index) => (
          <div
            onClick={() => {
              setOpen(true);
              setEdititem(item);
            }}
          >
            <ItemCard title={item.name} />
            {index !== transactionlimit.tiers.length - 1 && (
              <hr className="border-grey" />
            )}
          </div>
        ))}
      </div>

      {editItem && open && (
        <Modal
          isVisible={open}
          close={setOpen}
          title={editItem.name}
          center
          header
          width="medium"
          footer={
            <div className="flex justify-end">
              <Button onClick={submit} loading={isLoading} disabled={isLoading}>
                Save Changes
              </Button>
            </div>
          }
          showFooter
        >
          <div className="flex flex-col gap-4 text-sm">
            <div>
              <h5 className="mb-2">Name</h5>
              <Input defaultValue={editItem.name} readOnly />
            </div>
            <div>
              <h5 className="mb-2">Transfer Per Time</h5>
              <Input
                defaultValue={editItem.transferPerTime}
                onChange={(e) =>
                  setEdititem({
                    ...editItem,
                    transferPerTime: Number(e.target.value),
                  })
                }
              />
            </div>
            <div>
              <h5 className="mb-2">Balance</h5>
              <Input
                type="text"
                onChange={(e) =>
                  setEdititem({
                    ...editItem,
                    balance: Number(e.target.value),
                  })
                }
                className={`text-xs ${balance === true ? 'opacity-50' : ''}`}
                defaultValue={
                  editItem.balance === 'Unlimited' ? '' : editItem.balance
                }
                disabled={balance === true}
                trailing={
                  <div className="flex items-center text-xs">
                    <h6 className="mr-2">Unlimited</h6>
                    <Switch checked={balance} onCheck={handleBalanceChange} />
                  </div>
                }
              />
            </div>
            <div>
              <h5 className="mb-2">Limit Per Day</h5>
              <Input
                type="text"
                onChange={(e) =>
                  setEdititem({
                    ...editItem,
                    limitPerDay: Number(e.target.value),
                  })
                }
                className={`text-xs ${limit === true ? 'opacity-50' : ''}`}
                defaultValue={
                  editItem.limitPerDay === 'Unlimited'
                    ? ''
                    : editItem.limitPerDay
                }
                disabled={limit === true}
                trailing={
                  <div className="flex items-center text-xs">
                    <h6 className="mr-2">Unlimited</h6>
                    <Switch checked={limit} onCheck={handleLimitChange} />
                  </div>
                }
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TransactionLimits;
