import { useContext, useEffect, useState } from 'react';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import ItemCard from '../../../../../components/itemCard';
import Switch from '../../../../../components/switch';
import GlobalContext from '../../../../../context/globalContext';
import {
  IGiftCardBuySubChild,
  IGiftCardSellSubChild,
  ISettingsResponse,
} from '../../../../../interface/settings';
import {
  useGetGiftCardVoucher,
  useUpdateSettingsData,
} from '../../../../../services/settings';
import AddGiftCards from './add-giftcard';
import GiftcardDetails from './gift-card-details';
import { Back, Loading } from '../../../../../components/icons';
import PreviewGiftCard from './preview-giftcard';
import { useToast } from '../../../../../context/toast';
import { Modal } from '../../../../../components/modal';
import { capitalized } from '../../../../../utils/capitalize';

interface IGiftCards {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const GiftCards = ({ settingsData, refetch }: IGiftCards) => {
  const giftCardsSettingsData = settingsData?.giftcard;
  const { mutate, isLoading } = useUpdateSettingsData();
  const { data } = useGetGiftCardVoucher();
  const { progressCount, setProgressCount, view, setView, setGiftCardDetails } =
    useContext(GlobalContext);
  const [openGiftCardBuy, setOpenGiftCardBuy] = useState<boolean>(false);
  const [openGiftCardSell, setOpenGiftCardSell] = useState<boolean>(false);
  const [buyGiftCardActive, setBuyGiftCardActive] = useState<boolean>(
    giftCardsSettingsData?.buy.active ?? false
  );
  const [editGiftCardBuy, setEditGiftCardBuy] = useState<
    IGiftCardBuySubChild[]
  >(giftCardsSettingsData?.buy.isp ?? []);
  const [editGiftCardSell, setEditGiftCardSell] = useState<
    IGiftCardSellSubChild[]
  >(giftCardsSettingsData?.sell.isp ?? []);
  const [sellGiftCardActive, setSellGiftCardActive] = useState<boolean>(
    giftCardsSettingsData?.sell.active ?? false
  );
  const giftCard = data?.data;

  const { addToast } = useToast();

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: 'Successfully Updated', type: 'success' });
  };

  const toggleBuyGiftCard = () => {
    setBuyGiftCardActive(!buyGiftCardActive);
    mutate(
      {
        giftcard: {
          buy: {
            active: !giftCardsSettingsData?.buy.active,
            isp: giftCardsSettingsData?.buy.isp,
          },
          sell: giftCardsSettingsData?.sell,
        },
      },
      { onSuccess: handleMutationSuccess }
    );
  };

  const toggleSellGiftCard = () => {
    setSellGiftCardActive(!sellGiftCardActive);
    mutate(
      {
        giftcard: {
          buy: giftCardsSettingsData?.buy,
          sell: {
            active: !giftCardsSettingsData?.sell.active,
            isp: giftCardsSettingsData?.sell.isp,
          },
        },
      },
      { onSuccess: handleMutationSuccess }
    );
  };

  const handleBuyGiftCardActive = (item: IGiftCardBuySubChild) => {
    setEditGiftCardBuy(
      editGiftCardBuy.map((data) =>
        data.provider === item.provider
          ? { ...item, active: !item.active }
          : data
      )
    );
  };

  const handleSenderFee = (item: IGiftCardBuySubChild) => {
    setEditGiftCardBuy(
      editGiftCardBuy.map((data) =>
        data.provider === item.provider
          ? { ...item, hasSenderFee: !item.hasSenderFee }
          : data
      )
    );
  };

  const handleSellGiftCardActive = (item: IGiftCardSellSubChild) => {
    setEditGiftCardSell(
      editGiftCardSell.map((data) =>
        data.provider === item.provider
          ? { ...item, active: !item.active }
          : data
      )
    );
  };

  const handleInputChange = (
    value: string,
    inputName: string,
    index: number
  ) => {
    setEditGiftCardBuy((prevState) => {
      const updatedInputs: IGiftCardBuySubChild[] = [...prevState];
      if (inputName === 'amountPerUSD') {
        updatedInputs[index].amountPerUSD = Number(value);
      } else if (inputName === 'currentRate') {
        updatedInputs[index].currentRate = Number(value);
      } else if (inputName === 'lastRate') {
        updatedInputs[index].lastRate = Number(value);
      }
      return updatedInputs;
    });
  };

  const submitGiftCardBuy = () => {
    mutate(
      {
        giftcard: {
          buy: {
            active: giftCardsSettingsData?.buy.active,
            isp: editGiftCardBuy,
          },
          sell: giftCardsSettingsData?.sell,
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpenGiftCardBuy(false);
        },
      }
    );
  };

  const submitGiftCardSell = () => {
    mutate(
      {
        giftcard: {
          buy: giftCardsSettingsData?.buy,
          sell: {
            active: giftCardsSettingsData?.sell.active,
            isp: editGiftCardSell,
          },
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpenGiftCardSell(false);
        },
      }
    );
  };

  const handleFoward = () => {
    setProgressCount(progressCount + 1);
  };

  const handleBack = () => {
    setProgressCount(progressCount - 1);
  };

  useEffect(() => {
    setProgressCount(0);
  }, []);

  return (
    <>
      {progressCount === 0 ? (
        <div className="md:w-96 w-full text-sm">
          <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
            <h5>Activate Giftcard Buy</h5>
            <div className="flex gap-3 items-center">
              <h3
                className="text-sm cursor-pointer"
                onClick={() => setOpenGiftCardBuy(true)}
              >
                Edit
              </h3>
              <Switch checked={buyGiftCardActive} onCheck={toggleBuyGiftCard} />
            </div>
          </div>
          <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
            <h5>Activate Giftcard Sell</h5>
            <div className="flex gap-3 items-center">
              <h3
                className="text-sm cursor-pointer"
                onClick={() => setOpenGiftCardSell(true)}
              >
                Edit
              </h3>
              <Switch
                checked={sellGiftCardActive}
                onCheck={toggleSellGiftCard}
              />
            </div>
          </div>
          <div className="w-40 mb-6">
            <Button
              className="!py-3"
              onClick={() => {
                handleFoward();
                setView('add');
              }}
            >
              Add New Card
            </Button>
          </div>
          <div className="rounded-md bg-dark mb-6">
            {giftCard?.map((giftcard, index) => (
              <div key={index}>
                <div
                  onClick={() => {
                    handleFoward();
                    setView('edit');
                    setGiftCardDetails(giftcard);
                  }}
                >
                  <ItemCard title={giftcard.brandName} />
                  {giftCard.length - 1 !== index && (
                    <hr className="border-grey" />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="rounded-md bg-dark mb-6 px-2 py-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <h5>Page</h5>
              <div className="flex">
                <Input
                  parentClassName="rounded-tr-none rounded-br-none py-0 w-10"
                  className="text-center"
                />
                <Button className="py-sm px-3 rounded-tl-none rounded-bl-none">
                  Go
                </Button>
              </div>
            </div>
            <div className="flex gap-2">
              <Button>Prev Page</Button>
              <Button>Next Page</Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {progressCount === 1 && view === 'edit' && <GiftcardDetails />}
          <div>
            {progressCount === 1 && view === 'add' && (
              <div>
                <div className="flex-1 mb-6">
                  <div className="flex gap-4 items-center">
                    <div
                      className="p-1 bg-lightGray rounded-md"
                      onClick={handleBack}
                    >
                      <Back />
                    </div>
                    <h2 className="text-sm font-medium">Add Card</h2>
                  </div>
                </div>
                <AddGiftCards />
              </div>
            )}

            {progressCount === 2 && view === 'add' && (
              <div>
                <div className="flex-1 mb-6">
                  <div className="flex gap-4 items-center">
                    <div
                      className="p-1 bg-lightGray rounded-md"
                      onClick={handleBack}
                    >
                      <Back />
                    </div>
                    <h2 className="text-sm font-medium">Add Card</h2>
                  </div>
                </div>
                <PreviewGiftCard />
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        isVisible={openGiftCardBuy}
        close={setOpenGiftCardBuy}
        title="Buy Gift Card"
        header
        padding={false}
        center
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              onClick={submitGiftCardBuy}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        {editGiftCardBuy.map((item: IGiftCardBuySubChild, index) => (
          <div key={index} className="gap-6 flex flex-col py-4">
            <div className="px-4 flex justify-between items-center text-xs">
              <div>{capitalized(item.provider)}</div>
              <span className="max-w-[150px] w-auto flex justify-end">
                <Switch
                  checked={item.active}
                  onCheck={() => handleBuyGiftCardActive(item)}
                />
              </span>
            </div>
            <div className="px-4 flex justify-between items-center text-xs">
              <div>Sender Fee</div>
              <span className="max-w-[150px] w-auto flex justify-end">
                <Switch
                  checked={item.hasSenderFee}
                  onCheck={() => handleSenderFee(item)}
                />
              </span>
            </div>{' '}
            <div className=" px-4 flex justify-between items-center text-xs">
              <h5>Amount Per USD:</h5>
              <Input
                defaultValue={item.amountPerUSD}
                onChange={(e) =>
                  handleInputChange(e.target.value, 'amountPerUSD', index)
                }
                parentClassName="py-2 w-[15%]"
              />
            </div>
            <div className=" px-4 flex justify-between items-center text-xs">
              <h5>Current Rate:</h5>
              <Input
                defaultValue={item.currentRate}
                onChange={(e) =>
                  handleInputChange(e.target.value, 'currentRate', index)
                }
                parentClassName="py-2 w-[15%]"
              />
            </div>
            <div className=" px-4 flex justify-between items-center text-xs">
              <h5>Last Rate:</h5>
              <Input
                defaultValue={item.lastRate}
                onChange={(e) =>
                  handleInputChange(e.target.value, 'lastRate', index)
                }
                parentClassName="py-2 w-[15%]"
              />
            </div>
          </div>
        ))}
      </Modal>
      <Modal
        isVisible={openGiftCardSell}
        close={setOpenGiftCardSell}
        title="Sell Gift Card"
        header
        padding={false}
        center
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              onClick={submitGiftCardSell}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        {editGiftCardSell.map((item: IGiftCardSellSubChild, index) => (
          <div key={index}>
            <div className="py-4 px-4 flex justify-between items-center text-xs">
              <div>{capitalized(item.provider)}</div>
              <div className="flex items-center justify-end">
                <span className="max-w-[150px] w-auto flex justify-end">
                  <Switch
                    checked={item.active}
                    onCheck={() => handleSellGiftCardActive(item)}
                  />
                </span>
              </div>
            </div>
            {index !== editGiftCardSell.length - 1 && (
              <hr className="border-grey/30" />
            )}
          </div>
        ))}
      </Modal>
    </>
  );
};

export default GiftCards;
