import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useUrlPagination from '../../../../utils/urlPagination';
import { useInactiveUsers } from '../../../../services/user';
import Pagination from '../../../../components/pagination';
import {
  InactiveUserFilter,
  InactiveUsers as InactiveUsersInterface,
  PeriodEnum,
} from '../../../../interface/user';
import { Link } from 'react-router-dom';
import { Back, Search } from '../../../../components/icons';
import Input from '../../../../components/input';
import _ from 'lodash';
import Dropdown from '../../../../components/dropdown';
import { capitalized } from '../../../../utils/capitalize';

const InactiveUsers = () => {
  const [size, setSize] = useState(10);
  const { currentPage, setPage } = useUrlPagination();
  const [filter, setFilter] = useState<InactiveUserFilter>();

  const { data, isLoading, refetch } = useInactiveUsers({
    page: currentPage,
    limit: size,
    period: filter?.period ?? PeriodEnum.WEEK,
    ...filter,
  });

  const periodFilter = Object.entries(PeriodEnum).map(([label, value]) => ({
    label: capitalized(label).replace(/_/g, ' '),
    value,
  }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, maxTransactions: Number(e.target.value) });

  const debouncedResults = useMemo(() => {
    return _.debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (filter) setPage(1);
  }, [filter]);

  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <div className="flex-1">
          <div className="flex gap-4 items-center">
            <Link to={'/app/user'} className="p-2 bg-lightGray rounded-md">
              <Back />
            </Link>
            <h2 className="font-medium">
              Inactive Users ({data?.data.meta.total})
            </h2>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <Dropdown
          label="Period"
          option={periodFilter}
          onChange={(e) => setFilter({ ...filter, period: e?.value })}
        />
        <Input
          type="number"
          placeholder="Maximum transactions"
          leading={<Search />}
          onChange={debouncedResults}
        />
        {filter?.period === PeriodEnum.CUSTOM ? (
          <>
            <Input
              type="date"
              className="text-xs"
              leading={<span className="opacity-50">Start</span>}
              onChange={(e) =>
                setFilter({ ...filter, startDate: e.target.value.trim() })
              }
            />
            <Input
              type="date"
              className="text-xs"
              leading={<span className="opacity-50">End</span>}
              onChange={(e) =>
                setFilter({ ...filter, endDate: e.target.value.trim() })
              }
            />
          </>
        ) : null}
      </div>

      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto tableScrollBar">
          <div className=" inline-block min-w-full ">
            <div className=" overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-lightGray">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Email Address
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Transaction Count
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Total Settlement
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Inactive Reason
                    </th>
                  </tr>
                </thead>
                {!isLoading && (
                  <tbody>
                    {data?.data?.data?.map(
                      (item: InactiveUsersInterface, index) => (
                        <tr key={index} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.firstName} {item.lastName}
                          </td>

                          <td className="text-sm p-4">{item.email}</td>
                          <td className="text-sm p-4">
                            {item.transactionCount}
                          </td>
                          <td className="text-sm p-4">
                            {item.totalSettlement}
                          </td>
                          <td className="text-sm p-4">{item.inactiveReason}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {data?.data.meta.total! > 0 && (
          <Pagination
            totalItems={data?.data.meta.total!}
            itemsPerPage={size}
            setPage={setPage}
            page={currentPage}
            setSize={setSize}
          />
        )}
      </div>
    </>
  );
};

export default InactiveUsers;
