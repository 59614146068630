import React, { useState } from 'react';
import Input from '../../../../../components/input';
import { useProviderRefund, useUsers } from '../../../../../services/user';
import Switch from '../../../../../components/switch';
import Button from '../../../../../components/button';
import { useToast } from '../../../../../context/toast';

const ProviderRefund = () => {
  const [acctNo, setAcctNo] = useState<string>('');
  const [filter, setFilter] = useState<boolean>(false);
  const [amount, setAmount] = useState('');
  const [isBusiness, setIsBusiness] = useState(false);
  const [secret, setSecret] = useState('');
  const { mutate, isLoading } = useProviderRefund();
  const { addToast } = useToast();

  const { data } = useUsers({
    search: filter ? acctNo : undefined,
  });

  const submit = () => {
    if (!secret) {
      addToast({ msg: 'Please enter secret', type: 'error' });
    } else if (!amount) {
      addToast({ msg: 'Please enter amount', type: 'error' });
    } else {
      mutate(
        {
          _id: data?.data.response[0]._id!,
          amount: Number(amount),
          secret,
          business: isBusiness,
          bank: data?.data.response[0].account[0].bankName!,
          accountName: data?.data.response[0].account[0].accountName!,
          accountNumber: data?.data.response[0].account[0].accountNumber!,
        },
        {
          onSuccess: () => {
            setAcctNo('');
          },
        }
      );
    }
  };

  return (
    <div className="md:w-[30rem] w-full text-sm">
      <div className="bg-dark rounded-t-md px-4 py-4">
        {filter ? (
          data?.data.response &&
          data?.data.response.length > 0 && (
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <h6>Customer Name: </h6>
                <h6>
                  {`${data.data.response[0].firstName} ${data.data.response[0].lastName}`}
                </h6>
              </div>
              <div className="flex gap-2">
                <h6>Customer ID:</h6>
                <h6>{data.data.response[0]._id}</h6>
              </div>
              <div className="flex justify-between items-center">
                <h6>Business</h6>
                <Switch
                  checked={isBusiness}
                  onCheck={() => setIsBusiness(!isBusiness)}
                />
              </div>
              <div className="flex justify-between items-center">
                <h6>Amount</h6>
                <Input
                  onChange={(e) => setAmount(e.target.value)}
                  parentClassName="w-2/3"
                />
              </div>
              <div className="flex justify-between items-center">
                <h6>Secret</h6>
                <Input
                  onChange={(e) => setSecret(e.target.value)}
                  parentClassName="w-2/3"
                />
              </div>
            </div>
          )
        ) : (
          <div>
            <h5 className="text-sm mb-4">Enter Account Number</h5>
            <Input
              onChange={(e) => setAcctNo(e.target.value)}
              trailing={
                <h6
                  onClick={() => {
                    acctNo.length === 10 && setFilter(true);
                  }}
                >
                  Search
                </h6>
              }
            />
          </div>
        )}
      </div>
      <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
        <Button
          onClick={submit}
          loading={isLoading}
          disabled={isLoading || acctNo.length === 0}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ProviderRefund;
